@if (showIcon){
  <i class="icon xs" [class]="'i-'+ status" ></i>
}
@if (showLabel) {
  <div
    class="ellipsis label"
    [class.with-spinner]="showSpinner && progress"
  >{{experimentsStatusLabels[status] || status}}</div>
}
@if (showSpinner && enableSpinner) {
  <mat-progress-spinner
    class="spinner"
    [class.show]="showSpinner && progress"
    [value]="progress"
    [diameter]="18"
    color="accent"
  ></mat-progress-spinner>
} @else {
  <div></div>
}

